import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'Rim',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      42262: "0x54b74632cd2057accf84b67657a972fea2e1b2c4"
    },
    tokenSymbol: 'Rim',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      42262: "0x0a4c3764d494251c074defa528a7515fa3c4ecec"
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'Rim-Rose LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      42262: "0x54b74632cd2057accf84b67657a972fea2e1b2c4"
    },
    tokenSymbol: 'Rim',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      42262: "0x21C718C22D52d0F3a789b752D4c2fD5908a8A733"
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'Eth-Btc LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      42262: "0x9dbd257779e54b96fb756806b1dfb03ed4759b22"
    },
    tokenSymbol: 'Eth',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      42262: "0x3223f17957Ba502cbe71401D55A0DB26E5F7c68F"
    },
    quoteTokenSymbol: QuoteToken.ETH,
    quoteTokenAdresses: contracts.eth,
  },
  {
    pid: 3,
    risk: 5,
    lpSymbol: 'Rim-Usdt LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019', // 0x94fbffe5698db6f54d6ca524dbe673a7729014be
      42262: "0xa95c1fd77c59f3af71fcc43b408012f313674993"
    },
    tokenSymbol: 'Usdt',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      42262: "0xdC19A122e268128B5eE20366299fc7b5b199C8e3"
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 4,
    risk: 5,
    lpSymbol: 'Usdt-Usdc LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019', // 0x94fbffe5698db6f54d6ca524dbe673a7729014be
      42262: "0x94fbfFe5698DB6f54d6Ca524DbE673a7729014Be"
    },
    tokenSymbol: 'Usdt',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      42262: "0xdC19A122e268128B5eE20366299fc7b5b199C8e3"
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },

]

export default farms
