export default {
  cake: {
    56: '0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
    97: '',
    42262: "0x0A4c3764d494251C074DeFA528a7515fA3c4EcEC"
  },
  masterChef: {
    56: '0xe70E9185F5ea7Ba3C5d63705784D8563017f2E57',
    97: '',
    42262: "0x62DB4e7C8dfc647d937209f04a669604330AdAC3"
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
    42262: "0x21C718C22D52d0F3a789b752D4c2fD5908a8A733"
  },
  eth: {
    56: "",
    42262: "0x3223f17957Ba502cbe71401D55A0DB26E5F7c68F"
  },
  btc: {
    56: "",
    42262: "0xd43ce0aa2a29DCb75bDb83085703dc589DE6C7eb"
  },
  usdt: {
    56: "",
    42262: "0xdC19A122e268128B5eE20366299fc7b5b199C8e3"
  },
  usdc: {
    56: "",
    42262: "0x94fbfFe5698DB6f54d6Ca524DbE673a7729014Be"
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
    42262: "0x970F9F4d7D752423A82f4a790B7117D949939B0b"
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
    42262: "0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C"
  },

}
